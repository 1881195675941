.app {
  position: relative; /* Ensure proper positioning for the overlay and content */
  min-height: 100vh; /* Ensure the app takes up at least the full viewport height */
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Adjust image scaling as needed */
  z-index: -2; /* Place the image behind the overlay */
}
