.scroll-to-top-button {
    position: fixed;
    bottom: 20px; /* Adjust this value to control the distance from the bottom */
    right: 20px; /* Adjust this value to control the distance from the right */
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
  }
  
  .scroll-to-top-button.visible {
    opacity: 1;
    visibility: visible;
  }