/* Overlay.css */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(251, 244, 240, 0.89); /* Adjust the overlay color and opacity */
    z-index: -1; /* Place the overlay behind other content */
  }
  